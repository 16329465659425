import React from "react"
import {ThreeCircles} from 'react-loader-spinner'


const Loader =  ()=>{
    return (
        <div style={{display:'flex', justifyContent:'center', alignItems:'center',minHeight:'80vh'}}>
            <ThreeCircles
                color="#fa7449"
                height={110}
                width={110}
                ariaLabel="three-circles-rotating"
                />
        </div>
    )
}

export default Loader