import React, { useState, lazy, useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "../routes";
import { AuthContext } from "../context/AuthContext";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import { Suspense } from "react";
import StudentLogin from "./studentside/StudentLogin/Login";
import OurTeam from "./studentside/OurTeam";
import toast, { Toaster } from "react-hot-toast";

//Student Side pages
const CompanyDetails = lazy(() => import("./studentside/CompanyDetails"));
const CompanyNoticeDetails = lazy(() =>
  import("./studentside/CompanyNoticeDetails")
);
const AllCompanies = lazy(() => import("./studentside/AllCompanies"));
const CompanyNotifications = lazy(() =>
  import("./studentside/CompanyNotifications")
);
const Profile = lazy(() => import("./profile/"));
const Application = lazy(() => import("./studentside/Application"));
const Login = lazy(() => import("./studentside/StudentLogin/Login"));
const Register = lazy(() => import("./studentside/StudentLogin/Register"));
const Contact = lazy(() => import("./studentside/Support/Contact"));
const TnPGuidelines = lazy(() => import("./studentside/Support/TnPGuidelines"));
const ResetPassword = lazy(() => import("./ResetPassword"));
const ForgotPassword = lazy(() => import("./ForgotPassword"));
const FeedbackForm = lazy(() =>
  import("./studentside/Support/feedback/Feedback")
);
const MyFeedbacks = lazy(() =>
  import("./studentside/Support/feedback/MyFeedbacks")
);

const LazyLoadedComponent = ({ children }) => {
  return <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>;
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const {
    state: { isAuthenticated },
  } = useContext(AuthContext);

  const [loaded] = useState(false);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded} />
          <Sidebar />

          <main className="content">
            <Navbar />
            <LazyLoadedComponent>
              <Component {...props} />
            </LazyLoadedComponent>
            <Footer
              toggleSettings={toggleSettings}
              showSettings={showSettings}
            />
          </main>
        </>
      )}
    />
  );
};

export default () => {
  const {
    state: { isLoading },
  } = useContext(AuthContext);
  if (isLoading) {
    return <Preloader show={true} />;
  }

  useEffect(() => {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "denied") {
          toast.error("Allow notification in site setting to stay updated!");
        }
      });
    }
  }, []);

  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{ duration: 5000 }}
      />
      <Switch>
        <Route
          exact
          path="/login"
          render={(props) => (
            <LazyLoadedComponent>
              <Login {...props} />
            </LazyLoadedComponent>
          )}
        />
        <Route
          exact
          path="/register"
          render={(props) => (
            <LazyLoadedComponent>
              <Register {...props} />
            </LazyLoadedComponent>
          )}
        />
        <Route
          exact
          path="/forgot-password"
          render={(props) => (
            <LazyLoadedComponent>
              <ForgotPassword {...props} />
            </LazyLoadedComponent>
          )}
        />
        <Route
          exact
          path="/reset-password"
          render={(props) => (
            <LazyLoadedComponent>
              <ResetPassword {...props} />
            </LazyLoadedComponent>
          )}
        />
        <RouteWithSidebar
          exact
          path={Routes.AllCompanies.path}
          component={AllCompanies}
        />

        <RouteWithSidebar
          exact
          path={Routes.CompanyDetails.path}
          component={CompanyDetails}
        />
        <RouteWithSidebar
          exact
          path={Routes.CompanyNoticeDetails.path}
          component={CompanyNoticeDetails}
        />
        <RouteWithSidebar
          exact
          path={Routes.Profile.path}
          component={Profile}
        />
        <RouteWithSidebar
          exact
          path={Routes.Application.path}
          component={Application}
        />
        <RouteWithSidebar
          exact
          path={Routes.CompanyNotifications.path}
          component={CompanyNotifications}
        />
        <RouteWithSidebar
          exact
          path={Routes.TnPGuidelines.path}
          component={TnPGuidelines}
        />
        <RouteWithSidebar
          exact
          path={Routes.ResetPassword.path}
          component={ResetPassword}
        />
        <RouteWithSidebar
          exact
          path={Routes.Contact.path}
          component={Contact}
        />
        <RouteWithSidebar
          exact
          path={Routes.OurTeam.path}
          component={OurTeam}
        />
        <RouteWithSidebar
          exact
          path={Routes.StudentLogin.path}
          component={StudentLogin}
        />
        <RouteWithSidebar
          exact
          path={Routes.Feedback.path}
          component={FeedbackForm}
        />
        <RouteWithSidebar
          exact
          path={Routes.MyFeedback.path}
          component={MyFeedbacks}
        />
      </Switch>
    </>
  );
};
