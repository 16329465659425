export const Routes = {
  //Student Side Routes
  CompanyDetails: { path: "/companies/:eventid" },
  CompanyNoticeDetails: { path: "/notices/:eventid" },
  AllCompanies: { path: "/companies" },
  CompanyNotifications: { path: "/" },
  Profile: { path: "/Profile" },
  Application: { path: "/Application" },
  StudentLogin: { path: "/login" },
  Contact: { path: "/contact-us" },
  TnPGuidelines: { path: "/tnp-guidelines" }, 
  ResetPassword: { path: "/reset-password" },
  ForgotPassword: { path: "/forgot-password" },
  Login: { path: "/login" },
  Register: { path: "/register" },
  OurTeam: {path:"/team"},
  Feedback: {path: "/feedback"},
  MyFeedback: {path: "/my-feedbacks"}
};
