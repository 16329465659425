import React, { Component, createContext } from "react";
import axiosInstance from "../axiosConfig";

export const AuthContext = createContext();


class AuthContextProvider extends Component {
  state = {
    isAuthenticated: false,
    isLoading: true,
    loginError: null,
    registerError: null,
    userDetails: null,
    profileData: null,
    jwt: null,
  };

  setLoading = (val) => {
    this.setState({
      ...this.state,
      isLoading: val,
    });
  };

  login = async ({ email, password }) => {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const response = await axiosInstance.post("/auth/local/", {
        identifier: email,
        password,
      });

      localStorage.setItem("tnp-student-jwt", response.data.jwt);
      window.location.href = "/";
    } catch (err) {
      console.log(err, err.response);
      this.setState({
        isAuthenticated: false,
        isLoading: false,
        userDetails: null,
        jwt: null,
        loginError: err?.response?.data?.message || "Error in logging in!",
      });
      window.location.href = "#login";
    }
  };

  new_register = async(formData) => {
    this.setState({
      ...this.state,
      registerError: null
    });
    try {
      const res = await axiosInstance.post("/new-registers", formData);
      return res;
  } catch (error) {
      // console.error('Error:', error.response ? error.response.data : error.message);
      this.setState({
        isAuthenticated: false,
        isLoading: false,
        registerError: error.response ? error.response.data : error.message,
        userDetails: null,
        jwt: null,
        loginError: null,
      });
  }
  };

  register = async ({ email, username, password }) => {
    return new Promise(async (resolve, reject) => {
      try {
        await axiosInstance.post("/auth/local/register", {
          username,
          email,
          password,
        });
        resolve();
      } catch (err) {
        console.log(err, err.response);
        this.setState({
          isAuthenticated: false,
          isLoading: false,
          userDetails: null,
          jwt: null,
          registerError: err.response.data.message || "Error in registering!",
        });

        reject(err.response.data.message || "Error in registering!");
      }
    });
  };

  logout = () => {
    this.setState({
      isAuthenticated: false,
      isLoading: false,
      userDetails: null,
      jwt: null,
    });

    localStorage.removeItem("tnp-student-jwt");
  };

  checkAuth = async () => {
    const jwt = localStorage.getItem("tnp-student-jwt");
    if (jwt) {
      try {
        const response = await axiosInstance.get("/users/me", {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        const profileData = await axiosInstance.get(`/students/me`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        this.setState({
          isAuthenticated: true,
          isLoading: false,
          userDetails: response.data,
          profileData: profileData.data,
          jwt,
        });
      } catch (err) {
        this.setState({
          isAuthenticated: false,
          isLoading: false,
          userDetails: null,
          jwt: null,
          loginError: "Session expired!",
        });

        localStorage.removeItem("tnp-student-jwt");
      }
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentDidMount() {
    this.checkAuth();
  }

  render() {
    // console.log(this.state);
    return (
      <AuthContext.Provider
        value={{
          login: this.login,
          new_register: this.new_register,
          register: this.register,
          logout: this.logout,
          state: this.state,
          setLoading: this.setLoading,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthContextProvider;
