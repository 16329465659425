import { Button, Col, Form, Row } from "@themesberg/react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../../../context/AuthContext";
import bglogin from "../../../assets/img/register_bg_2.png";
import Loader from "../../../components/Loader";
import { Link, Redirect } from "react-router-dom";
const StudentLogin = () => {
  const { login, state } = useContext(AuthContext);
  const { isLoading, isAuthenticated } = state;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (state.loginError?.[0]["messages"]?.[0]["message"] != null) {
      toast.error(state.loginError?.[0]["messages"][0]["message"]);
    }
  }, [isAuthenticated, state.loginError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !password) {
      return;
    }
    login({ email, password });
  };

  if (isLoading) return <Loader />;

  if (isAuthenticated) {
    return <Redirect to="/" />;
  } else {
  }

  return (
    <div style={{ background: "#262b40" }}>
      <Toaster />
      <div
        className="d-flex justify-content-center px-2 align-items-center"
        style={{
          backgroundImage: `url(${bglogin})`,
          height: "100vh",
          width: "100vw",
          backgroundSize: "cover",
        }}
      >
        <div style={{ width: "400px", maxWidth: "100%", color: "orange" }}>
          <h2 style={{ color: "darkorange" }}>Login</h2>
          <Form onSubmit={handleSubmit} className="mt-3">
            <Row>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xs={12}>
                <Button
                  style={{
                    width: "100%",
                    background: "linear-gradient(to right, #fb7b4a, #ee3344)",
                  }}
                  type="submit"
                >
                  Login
                </Button>
              </Col>
            </Row>
          </Form>
          <div className="mt-2 text-white">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
          <div className="text-center">
            or
          </div>
          <div className="mt-2 p-2 text-white text-center" style={{
            width: "100%",
            borderRadius: "8px",
            background: "linear-gradient(to right, #fb7b4a, #ee3344)",
          }}>
            <Link
              to={'/register'}
              className="text-white text-center"
              style={{
                width: "100%",
                display: 'block'
              }}>
              Register
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StudentLogin;
