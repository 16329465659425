import React from "react";
import { Image } from "@themesberg/react-bootstrap";

// import ReactLogo from "../assets/img/technologies/react-logo-transparent.svg";
import NSUTLogo from "../assets/img/brand/nsut-logo-1800x1800.png";

export default (props) => {
  const { show } = props;

  return (
    <div
      className={`preloader bg-soft flex-column justify-content-center align-items-center ${
        show ? "" : "show"
      }`}
    >
      <Image className="loader-element " src={NSUTLogo} height={100} />
    </div>
  );
};
