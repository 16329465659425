import React from "react";
import { Toast } from "@themesberg/react-bootstrap";

import styles from "./GlobalNotification.module.css";

function GlobalNotification({ variant, message, toggleNotification }) {
  const handleClose = () => {
    toggleNotification(false);
  };
  return (
    <div className={styles.container}>
      <Toast show={true} onClose={handleClose}>
        <Toast.Header>
          <strong className="me-auto" style={{ textTransform: "capitalize" }}>
            {variant}!
          </strong>
          <small>Just now</small>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </div>
  );
}

export default GlobalNotification;
