import React from "react";
// import { Row, Col, Card } from "@themesberg/react-bootstrap";

export default () => {
  return (
    <div>
      {/* <footer className="footer section fixed-bottom pt-3">
        <Row>
          <Col
            xs={12}
            lg={6}
            className="mb-4 mb-lg-0"
            style={{ margin: "auto", width: "100%", textAlign: "center" }}
          >
            <p className="mb-0 text-center">
              Made by{" "}
              <Card.Link
                href=""
                target="_blank"
                className="text-blue text-decoration-none fw-normal"
              >
                DevComm, NSUT
              </Card.Link>
              .
            </p>
          </Col>
        </Row>
      </footer> */}
    </div>
  );
};
