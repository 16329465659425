import React from "react";
import "./ourteamcard.css";

const OurTeamCard = ({ data }) => {
  return (
    <div className="ourteamcard">
      <div className="image-wrp">
        <img src={data.img} alt={data.name} />
      </div>

      <div className="info-section">
        <div className="infotxt name">
          <p>{data.name}</p>
        </div>
        <div className="infotxt branch">
          <p>{data.branch}</p>
        </div>
      </div>

      <div className="ourteam-icon">
        <a
          href={data.linkedin}
          target="_blank"
          rel="noopener noreferrer"
          className="social linkedin"
        >
          <i className="fab fa-linkedin"></i>
        </a>
        {data.instagram && (
          <a
            href={data.instagram}
            target="_blank"
            rel="noopener noreferrer"
            className="social instagram"
          >
            <i className="fab fa-instagram"></i>
          </a>
        )}
      </div>
    </div>
  );
};

export default OurTeamCard;
