import React, { createContext, useState } from "react";
import GlobalNotification from "../components/GlobalNotification/GlobalNotification";

export const NotificationContext = createContext();

const NotificationContextProvider = ({ children }) => {
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationOpen, toggleNotification] = useState(false);

  const showNotification = (msg, variant = "success") => {
    toggleNotification(true);
    setNotificationMessage(msg);
  };

  const hideNotifcation = () => {
    toggleNotification(false);
    setNotificationMessage("");
  };

  return (
    <NotificationContext.Provider value={{ showNotification, hideNotifcation }}>
      {notificationOpen && (
        <GlobalNotification
          message={notificationMessage}
          toggleNotification={toggleNotification}
        />
      )}
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
