import React, { useState } from "react";
import Anurag from "../../assets/img/smarties/anurag.jpg";
import Ashutosh from "../../assets/img/smarties/ashutosh.jpg";
import Himank from "../../assets/img/smarties/himank.jpeg";
import Keshav from "../../assets/img/smarties/keshav.jfif";
import Shubham from "../../assets/img/smarties/shubham.jpg";
import Gautam from "../../assets/img/smarties/gautam.png";
import Naman from "../../assets/img/smarties/naman.jpeg";
import Samir from "../../assets/img/smarties/samir.jpg";
import Anuj from "../../assets/img/smarties/anuj.jpeg";
import Utpal from "../../assets/img/smarties/utpal.jpeg";
import Pranay from "../../assets/img/smarties/pranay.jpeg";
import OurTeamCard from "./components/ourTeamCard";
import "./ourteam.css";

const devsByYear = {
  2023: [
    {
      name: "Himank",
      img: Himank,
      branch: "ECE - 2023",
      linkedin: "https://www.linkedin.com/in/hm-varshney",
      instagram: "",
    },
    {
      name: "Naman Aggarwal",
      img: Naman,
      branch: "IT - 2023",
      linkedin: "https://www.linkedin.com/in/naman-aggarwal-9536341aa",
      instagram: "https://www.instagram.com/_naman.agg",
    },
  ],
  2024: [
    {
      name: "Anurag Verma",
      img: Anurag,
      branch: "COE - 2024",
      linkedin: "https://www.linkedin.com/in/anurag2276/",
      instagram: "https://www.instagram.com/aakash3675/",
    },
    {
      name: "Ashutosh Rai",
      img: Ashutosh,
      branch: "IT - 2025",
      linkedin: "https://www.linkedin.com/in/ashutosh-rai-b75aa622a/",
      instagram: "https://instagram.com/ashutoshrai.in/",
    },
    {
      name: "Mohammed Samir",
      img: Samir,
      branch: "COE - 2024",
      linkedin: "https://www.linkedin.com/in/mohammed-samir-ab45531a5/",
      instagram: "https://www.instagram.com/samir._.pvt_/",
    },
  ],
  2025: [
    {
      name: "Ashutosh Rai",
      img: Ashutosh,
      branch: "IT - 2025",
      linkedin: "https://www.linkedin.com/in/ashutosh-rai-b75aa622a/",
      instagram: "https://instagram.com/ashutoshrai.in/",
    },
    {
      name: "Utpal Kumar",
      img: Utpal,
      branch: "ITNS - 2025",
      linkedin: "https://www.linkedin.com/in/utpal-kumar-3047561b6/",
      instagram: "https://www.instagram.com/utpalk904/",
    },
    {
      name: "Pranay Payal",
      img: Pranay,
      branch: "CSAI - 2025",
      linkedin: "https://www.linkedin.com/in/pranaypspayal/",
      instagram: "https://www.instagram.com/the_pranays/",
    },
  ],
};

const coodsByYear = {
  2025: [
    {
      name: "Anuj Kulshrestha",
      img: Anuj,
      branch: "MTech CSE- 2024",
      linkedin: "https://www.linkedin.com/in/anuj-kulshrestha-9ab7bb180/",
      instagram: "https://www.instagram.com/kulshrestha_anuj/",
    },
    {
      name: "Shubham Yadav",
      img: Shubham,
      branch: "CSE - 2025",
      linkedin: "https://www.linkedin.com/in/y1shubham",
      instagram:
        "https://instagram.com/y1shubham?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D",
    },
    {
      name: "Gautam Kanojia",
      img: Gautam,
      branch: "CSAI - 2025",
      linkedin: "https://www.linkedin.com/in/gautam-kanojiya-6211091ab/",
      instagram: "",
    },
    {
      name: "Keshav Shah",
      img: Keshav,
      branch: "EE - 2025",
      linkedin: "https://www.linkedin.com/in/keshav-sah-b05235229/",
      instagram: "",
    },
  ],
};

const OurTeam = () => {
  const [expandedYear, setExpandedYear] = useState("2025"); // Default expanded year for developers
  const [expandedCoodYear, setExpandedCoodYear] = useState(null); // For coordinators

  // Handle accordion toggle for developers
  const toggleYear = (year) => {
    if (expandedYear === year) {
      setExpandedYear(null); // Close if already expanded
    } else {
      setExpandedYear(year); // Open selected year
    }
  };

  // Handle accordion toggle for coordinators
  const toggleCoodYear = (year) => {
    if (expandedCoodYear === year) {
      setExpandedCoodYear(null); // Close if already expanded
    } else {
      setExpandedCoodYear(year); // Open selected year
    }
  };

  return (
    <div className="ourteamwrapper">
      <h2>Meet the Developers</h2>
      {Object.keys(devsByYear)
        .reverse()
        .map((year) => (
          <div key={year} className="accordion-item">
            <div className="accordion-header" onClick={() => toggleYear(year)}>
              <h3>{year}</h3>
              <span>{expandedYear === year ? "-" : "+"}</span>
            </div>

            {expandedYear === year && (
              <div className="accordion-content">
                <div className="devcardswrp">
                  {devsByYear[year].map((sn) => (
                    <OurTeamCard key={sn.name} data={sn} />
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}

      {/* Placement Coordinators Section */}
      <h2>Meet the Placement Coordinators</h2>
      {Object.keys(coodsByYear).map((year) => (
        <div key={year} className="accordion-item">
          <div
            className="accordion-header"
            onClick={() => toggleCoodYear(year)}
          >
            <h3>{year}</h3>
            <span>{expandedCoodYear === year ? "-" : "+"}</span>
          </div>

          {expandedCoodYear === year && (
            <div className="accordion-content">
              <div className="devcardswrp">
                {coodsByYear[year].map((sn) => (
                  <OurTeamCard key={sn.name} data={sn} />
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default OurTeam;
