import {
  faBuilding,
  faChartPie,
  faClipboardCheck,
  faFile,
  faInfoCircle,
  faPhoneAlt,
  faSignOutAlt,
  faTimes,
  faUser,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import SimpleBar from "simplebar-react";
import NSUTLogoNew from "../assets/img/brand/nsut-logo-new.png";

import { Badge, Button, Image, Nav, Navbar } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { AuthContext } from "../context/AuthContext";
import { Routes } from "../routes";

export default () => {
  const { state } = useContext(AuthContext);
  const { logout } = useContext(AuthContext);

  const user = state?.userDetails;

  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={NSUTLogoNew}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={Routes.CompanyNotifications.path}
        >
          <Image src={NSUTLogoNew} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="d-block">
                  <h6>{user?.username}</h6>
                  <Button
                    as={Link}
                    variant="secondary"
                    size="xs"
                    to={Routes.StudentLogin.path}
                    className="text-dark"
                    onClick={() => {
                      logout();
                      window.location.href = "#login";
                    }}
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <Nav.Item onClick={() => setShow(false)}>
                <Nav.Link style={{ textAlign: "center" }}>
                  <div style={{ textAlign: "center" }}>
                    <Image
                      src={NSUTLogoNew}
                      width={90}
                      height={90}
                      className="sidebar-icon svg-icon"
                    />
                  </div>
                  <div className="sidebar-text" style={{ textAlign: "center" }}>
                    TnP Student Portal
                  </div>
                </Nav.Link>
              </Nav.Item>
              <NavItem
                title="Dashboard"
                link={Routes.CompanyNotifications.path}
                icon={faChartPie}
              />
              <NavItem
                title="Company Events"
                link={Routes.AllCompanies.path}
                icon={faBuilding}
              />
              <NavItem
                title="Application"
                link={Routes.Application.path}
                icon={faFile}
              />
              <NavItem
                title="FeedBack Or Query"
                link={Routes.Feedback.path}
                icon={faClipboardCheck}
              />
              <NavItem
                title="My Feedbacks"
                link={Routes.MyFeedback.path}
                icon={faUserEdit}
              />
              <NavItem
                title="Profile"
                link={Routes.Profile.path}
                icon={faUser}
              />
              <NavItem
                title="Form & Docs"
                link={Routes.TnPGuidelines.path}
                icon={faInfoCircle}
              />
              <NavItem
                title="Contact Us"
                link={Routes.Contact.path}
                icon={faPhoneAlt}
              />
              <NavItem
                title="Our Team"
                link={Routes.OurTeam.path}
                icon={faUser}
              />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
